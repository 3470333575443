<template>
  <div id="rulers">
    <canvas id="ruler_x"></canvas>
    <canvas id="ruler_y"></canvas>
  </div>
</template>

<script>
export default {
  props: ['box'],
  data() {
    return {}
  },
  computed: {},
  mounted() {
    this.updateRulers(1)
  },
  beforeDestroy: function() {},
  methods: {
    /*
      标尺
    */

    updateRulers(zoom) {
      var r_intervals = []
      for (var i = 0.1; i < 1e5; i *= 10) {
        r_intervals.push(1 * i)
        r_intervals.push(2 * i)
        r_intervals.push(5 * i)
      }
      let z = zoom > 1 ? zoom : 1
      // var div = document.getElementById('rulers')
      var w = (this.box.width + 280) * z //1920
      var h = this.box.height * z //1920
      // var h = div.offsetHeight
      var limit = 30000
      var unit = 1

      for (var d = 0; d < 2; d++) {
        var is_x = d === 0
        var dim = is_x ? 'x' : 'y'

        var lentype = is_x ? 'width' : 'height'
        var content_d = 0
        var hcanv = document.getElementById('ruler_' + dim)
        let width = 20
        let height = h
        var ruler_len = h
        if (is_x) {
          width = w
          height = 20
          ruler_len = w
        }
        hcanv.width = width
        hcanv.height = height

        var total_len = ruler_len
        var ctx_num = 0
        var ctx_arr
        var ctx = hcanv.getContext('2d')

        ctx.fillStyle = 'rgb(200,0,0)'
        ctx.fillRect(0, 0, hcanv.width, hcanv.height)
        hcanv[lentype] = ruler_len
        var u_multi = unit * zoom
        var raw_m = 50 / u_multi
        var multi = 1
        for (var i = 0; i < r_intervals.length; i++) {
          var num = r_intervals[i]
          multi = num
          if (raw_m <= num) {
            break
          }
        }
        var big_int = multi * u_multi
        ctx.font = "normal 10px 'Lucida Grande', sans-serif"
        ctx.fillStyle = '#777'
        var ruler_d = ((content_d / u_multi) % multi) * u_multi
        var label_pos = ruler_d - big_int
        for (; ruler_d < total_len; ruler_d += big_int) {
          label_pos += big_int
          // var real_d = ruler_d - content_d

          var cur_d = Math.round(ruler_d) + 0.5
          if (is_x) {
            ctx.moveTo(cur_d, 15)
            ctx.lineTo(cur_d, 0)
          } else {
            ctx.moveTo(15, cur_d)
            ctx.lineTo(0, cur_d)
          }

          var num = (label_pos - content_d) / u_multi
          var label
          if (multi >= 1) {
            label = Math.round(num)
          } else {
            var decs = (multi + '').split('.')[1].length
            label = num.toFixed(decs) - 0
          }
          if (label !== 0 && label !== 1000 && label % 1000 === 0) {
            label = label / 1000 + 'K'
          }

          if (is_x) {
            ctx.fillText(label, ruler_d + 2, 10)
            ctx.fillStyle = '#777'
          } else {
            var str = (label + '').split('')
            for (var i = 0; i < str.length; i++) {
              ctx.fillText(str[i], 1, ruler_d + 9 + i * 9)
              ctx.fillStyle = '#777'
            }
          }

          var part = big_int / 10
          for (var i = 1; i < 10; i++) {
            var sub_d = Math.round(ruler_d + part * i) + 0.5
            if (ctx_arr && sub_d > ruler_len) {
              ctx_num++
              ctx.stroke()
              if (ctx_num >= ctx_arr.length) {
                i = 10
                ruler_d = total_len
                continue
              }
              ctx = ctx_arr[ctx_num]
              ruler_d -= limit
              sub_d = Math.round(ruler_d + part * i) + 0.5
            }

            var line_num = i % 2 ? 12 : 10
            if (is_x) {
              ctx.moveTo(sub_d, 15)
              ctx.lineTo(sub_d, line_num)
            } else {
              ctx.moveTo(15, sub_d)
              ctx.lineTo(line_num, sub_d)
            }
          }
        }
        ctx.strokeStyle = '#666'
        ctx.stroke()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#ruler_x {
  margin-left: 20px;
  position: relative;
}
#ruler_x {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}
#ruler_y {
  position: absolute;
  top: 20px;
  left: 0;
  z-index: 0;
}
</style>
